import React from "react";
import { Col, Container, Row } from "react-bootstrap";
// local import
import Layout from "../../components/Common/Layout";
import Seo from "../../components/Common/Seo";
import * as classes from "../../styles/testimonials.module.scss";
import VideoCard from "../../components/Testimonials/VideoCard";

const Testimonials = () => {
  return (
    <Layout>
      <Seo
        title="Our Success stories and mentions | Lucidity"
        description="Watch our Customers, Investors, and Partners, alike, talk about how Lucidity  is delivering the true cloud storage “NoOps” experience."
        canonicalUrl="https://www.lucidity.cloud"
      />
      <section className={classes.testimonials}>
        <Container className={classes.container}>
          <div className={classes.video_wrapper}>
            <Row>
              <Col
                sm="7"
                className={`${classes.testimonials__col} ms-1 ms-sm-2`}
              >
                <h1 className={classes.h1_heading}>
                  Our Success stories and mentions
                </h1>
                <p className={classes.sub_heading}>
                  Delivering the true cloud storage “NoOps” experience
                </p>
              </Col>
            </Row>
            <Row className={classes.testimonials__video_row}>
              <Col sm="4" className={classes.testimonials__col}>
                <VideoCard
                  videoCode="u8_byHmPEyw"
                  category="Customer"
                  designation="Jay Dhakar, Cloud Migration Architect - SpartanNash"
                />
              </Col>
              <Col sm="4" className={classes.testimonials__col}>
                <VideoCard
                  videoCode="DDdw0F6MKBI"
                  category="Investor"
                  designation="Dirk van Quaquebeke, Managing Partner - BEENEXT"
                />
              </Col>
              <Col sm="4" className={classes.testimonials__col}>
                <VideoCard
                  videoCode="EJyw22grBI4"
                  category="Partner"
                  designation="Joan Kuehl, Ex CIO - Elevate, Travelers"
                />
              </Col>
            </Row>

            <Row className={classes.testimonials__video_row}>
              <Col sm="4" className={classes.testimonials__col}>
                <VideoCard
                  videoCode="bCeubtlLclY"
                  category="Customer"
                  designation="Kunal Dawn, VP Engg - Bobble AI"
                />
              </Col>
              <Col sm="4" className={classes.testimonials__col}>
                <VideoCard
                  videoCode="nlOCtNrw5AY"
                  category="Investor"
                  designation="Andrew C. Martinez - Alpha Wave Global"
                />
              </Col>
              <Col sm="4" className={classes.testimonials__col}>
                <VideoCard
                  videoCode="bWdHLXBzSGI"
                  category="Partner"
                  designation="Kiersten Gaffney, CMO - Codefresh"
                />
              </Col>
            </Row>

            <Row className={classes.testimonials__video_row}>
              <Col sm="4" className={classes.testimonials__col}>
                <VideoCard
                  videoCode="0wHUN0Wjv0U"
                  category="Investor"
                  designation="Sanjay Nath, Co-founder & Managing Partner - Blume Ventures"
                />
              </Col>
              <Col sm="4" className={classes.testimonials__col}>
                <VideoCard
                  videoCode="Pf_uhRS28Rk"
                  category="Investor"
                  designation="Venk Krishnan, Founder - NuVentures"
                />
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </Layout>
  );
};

export default Testimonials;
