// extracted by mini-css-extract-plugin
export var category = "testimonials-module--category--5FaG4";
export var container = "testimonials-module--container--A10xC";
export var content = "testimonials-module--content--P32Ew";
export var designation = "testimonials-module--designation--sRnvV";
export var frame = "testimonials-module--frame--1cJa7";
export var h1_heading = "testimonials-module--h1_heading--IBMyc";
export var sub_heading = "testimonials-module--sub_heading--dVXLV";
export var testimonials = "testimonials-module--testimonials--TksIj";
export var testimonials__col = "testimonials-module--testimonials__col--7ucLY";
export var testimonials__video_row = "testimonials-module--testimonials__video_row--srUNi";
export var video_card = "testimonials-module--video_card--4TcwK";
export var video_card__wrapper = "testimonials-module--video_card__wrapper--Fy5L9";
export var video_wrapper = "testimonials-module--video_wrapper--IqM+7";