import React from "react";
// local import
import * as classes from "../../styles/testimonials.module.scss";

const VideoCard = ({ videoCode, category, designation }) => {
  return (
    <>
    <div className={classes.video_card}>
      <div className={classes.video_card__wrapper}>
        <iframe
          className={classes.frame}
          src={`https://www.youtube.com/embed/${videoCode}?modestbranding=1&autohide=1&showinfo=0`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </div>
      <div className={classes.content}>
        <div className={classes.category}>
          <p>{category}</p>
        </div>
        <p className={classes.designation}>{designation}</p>
      </div>
    </div>
    </>
  );
};

export default VideoCard;
